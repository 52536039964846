// Variables
@import "../variables";

// Colors
$primary-color: #db55ec; //#9610e0
$black-color: #030006;
$white-color: #f3edfb;

// Rest
@import "../frontend";
